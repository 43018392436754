@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --design-off-canvas-video-width: calc(100vw * (465 / 390));
  --design-off-canvas-video-aspect-ratio: calc(820 / 547);
  --design-off-canvas-video-width-lg: calc(50vw * (820 / 720));
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/* Homepage Redesign Classes */
.pl-box-shadow {
  box-shadow: 8px 8px 27px 0px rgba(2, 0, 57, 0.19), 0px 4px 8px 0px rgba(2, 0, 57, 0.12);
  -webkit-box-shadow: 8px 8px 27px 0px rgba(2, 0, 57, 0.19), 0px 4px 8px 0px rgba(2, 0, 57, 0.12);
}

.pl-box-shadow--light {
  box-shadow: 8px 8px 16px 0px rgba(2, 0, 57, 0.06), 0px 4px 6px 0px rgba(2, 0, 57, 0.06);
  -webkit-box-shadow: 8px 8px 16px 0px rgba(2, 0, 57, 0.06), 0px 4px 6px 0px rgba(2, 0, 57, 0.06);
}

.pl-box-shadow--lighter {
  box-shadow: 0px 4.64181px 13.26232px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 4.64181px 13.26232px 0px rgba(0, 0, 0, 0.15);
}

.pl-box-shadow--drop {
  box-shadow: 3px 5px 8px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 3px 5px 8px 0px rgba(0, 0, 0, 0.16);
}

.pl-button {
  @apply font-copy font-semibold text-18 px-6 py-4 leading-none tracking-[0.4px] rounded bg-jay-500 text-white relative w-fit block hover:bg-jay-600 transition-colors duration-300 ease-in-out pl-box-shadow;
}

.pl-button--dark {
  @apply bg-jay-900 hover:bg-jay-500;
}

.pl-input-text {
  @apply text-body-md py-3 px-4 w-full rounded;
}

.pl-rich-text p:not(:last-child) {
  @apply mb-6;
}

.pl-off-canvas-video {
  aspect-ratio: var(--design-off-canvas-video-aspect-ratio);
  width: var(--design-off-canvas-video-width);
}

.pl-header-w-highlight__highlight {
  @apply italic text-jay-500;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-11px);
  }
  100% {
    transform: translateY(0px);
  }
}

.pl-float:nth-child(odd) {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}
.pl-float:nth-child(even) {
  transform: translatey(0px);
  animation: float 5.75s ease-in-out infinite reverse;
}

.pl-float:nth-child(3n) {
  transform: translatey(0px);
  animation: float 7s ease-in-out 0.25s infinite reverse;
}

.pl-float:nth-child(4n) {
  transform: translatey(0px);
  animation: float 5.5s ease-in-out 0.5s infinite reverse;
}

.pl-float:nth-child(5n) {
  transform: translatey(0px);
  animation: float 7.25s ease-in-out 0.25s infinite;
}

@screen lg {
  .pl-button {
    @apply px-12;
  }

  .pl-button--submit {
    @apply px-4;
  }

  .pl-off-canvas-video {
    width: var(--design-off-canvas-video-width-lg);
  }
}
